<template>
    <div>
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="nav-icon fas fa-house-user"></i> Simuladores <small>Listagem de Simuladores</small></h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="nav-icon fas fa-home"></i> Home
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active"><i class="nav-icon fas fa-house-user"></i> Simuladores</li>
                        </ol>
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h3 class="card-title"> Listagem de Simuladores</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <router-link class="btn btn-app" :to="{ name: 'form-arquiteto', params: { id: 0 }}">
                                <i class="fas fa-house-user"></i> Cadastrar Simulador
                            </router-link>
                        </div>
                        <client-table :options="options">
                            <thead>
                                <tr>
                                    <th style="width: 200px;">Logo</th>
                                    <th>Site</th>
                                    <th>Email</th>
                                    <th>Data Cadastro</th>
                                    <th class="min-tablet" style="width: 70px;">Ações</th>
                                </tr>
                            </thead>
                        </client-table>
                    </div>
                    <div class="card-footer">
                    </div>
                    <div class="overlay">
                        <a class="btn btn-app bg-secondary">
                            <i class="fas fa-spinner fa-pulse"></i> carregando...
                        </a>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                options: {
                    "title": "Arquitetos",
                    "ajax": {
                        "url": "/api/arquiteto/datatable",
                        "data": function (d) {
                            return JSON.stringify($.extend({}, d, {
                            }));
                        }
                    },
                    "order": [[3, 'desc']],
                    "columns": [
                        {
                            "mData": 'logo',
                            "bSortable": false,
                            "sClass": "text-center py-0",
                            "mRender": function (data) {
                                return "<img src='"+data+"'/>";
                            }
                        },
                        { "mData": 'site' }, 
                        { "mData": 'email' },
                        { 
                            "mData": 'data_cadastro',
                            "mRender": function (data) {
                                const date = new Date(data);
                                const formattedDate = date.toLocaleDateString('pt-BR');
                                const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
                                return `${formattedDate} ${formattedTime}`;
                            }
                        },
                        {
                            "bSortable": false,
                            "mData": null,
                            "sClass": "text-center py-0",
                            "mRender": function (data, type, full) {
                                return `<div class="btn-group">
                                    <button onclick="$(this).trigger('edit', ${full.id_arquiteto})" rel="tooltip" title="Editar" class="btn bg-gradient-primary btn-sm"><i class="fas fa-pencil-alt"></i></button>
                                </div>`;
                            }
                        }
                    ]
                }
            };
        },
        mounted: function () {
            const vm = this;

            $("body").on("edit", function (event, id) { vm.$router.push({ name: 'form-arquiteto', params: { id: id } }).catch(() => { }); });
            $("body").on("view", function (event, id) { vm.$router.push({ name: 'view-arquiteto', params: { id: id } }).catch(() => { }); });
        }
    };
</script>

<style scoped>
</style>